import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import ScrollToTop from "~/containers/ScrollToTop";
import withTracker from "~/containers/GoogleAnalytics";
import { facebookAppId } from "~/config/socialMedia";
import { FacebookProvider } from "~/components/Facebook";
//import Bundle from 'containers/Bundle'
//import codesplit from 'util/codesplit'

/*
 * TODO:
 * write test configuration that would be able to use dynamic import syntax
 * and webpack2 code splitting
 * */

/* babel isnt ok with this : :'(
const LoaderIndex = codesplit('containers/LoaderIndex')
const Article = codesplit('containers/Article')
const CatchAll = codesplit('containers/CatchAll')
const RightNow = codesplit('containers/PageRightNow')
const Search = codesplit('containers/Search')
const Test = codesplit('containers/Test')
//*/
//
import PageIndex from "~/containers/pages/PageIndex";
import Article from "~/containers/pages/Article";
import CatchAll from "~/containers/pages/CatchAll";
import RightNow from "~/containers/pages/PageRightNow";
import SearchGoogle from "~/containers/pages/SearchGoogle";
import Search from "~/containers/pages/Search";
// import Test from 'containers/pages/Test'
// import LivescorePage from 'components/pages/Livescore'
//import AuthorSection from 'containers/pages/AuthorSection'
import Referendum from "~/containers/pages/PageReferendum";
import PhotodiaryListing from "~/containers/pages/PhotodiaryListing";
import PhotoTASRListing from "~/containers/pages/PhotoTASRListing";
import PhotodiaryArticle from "~/containers/pages/PhotodiaryArticle";
import PhotoTASRArticle from "~/containers/pages/PhotoTASRArticle";
import PhotoDayListing from "~/containers/pages/PhotoDayListing";
import PhotoDayArticle from "~/containers/pages/PhotoDayArticle";
import NewsNowRedirect from "~/containers/pages/NewsNowRedirect";
import PageSubscribeXML from "./pages/PageSubscribeXML";
import { dateToMonthRepr } from "~/components/photogallery/PhotoDayListing";
import type { GAInfo } from "~/utility/gaInfo";
import PageVolby2023 from "./pages/PageVolby2023";
import PageVolby2024 from "./pages/PageVolby2024";
import PageVolby2024temp from "./pages/PageVolby2024temp";
import PageEurovolby2024 from './pages/PageEurovolby2024';

const NoOp = (props) => <div>{JSON.stringify(props)}</div>;
/*
const Test = (props) => ( <Bundle load={import('containers/pages/Test')} {...props}/>)
*/

type DefaultProps = { isRoot: boolean; gaInfo: GAInfo };
type PropsT = { gaInfo: GAInfo };
export default class Routes extends Component<PropsT> {
  wrapPage;
  constructor(props) {
    super(props);
    const defaultProps = {
      isRoot: true,
      //setHeaderSuffix: this.props.setHeaderSuffix
      gaInfo: this.props.gaInfo,
    };
    this.wrapPage = this.wrapPageFactory(defaultProps);
  }

  wrapPageFactory(defaultProps: DefaultProps) {
    return function (WrappedComponent: Component) {
      const TrackedWrappedComponent = withTracker(WrappedComponent);
      return (props) => (
        <TrackedWrappedComponent {...defaultProps} {...props} />
      );
    };
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return true;
  }

  render() {
    return (
      <FacebookProvider appId={facebookAppId}>
        <ScrollToTop>
          <Switch>
            <Route exact path="/" component={this.wrapPage(PageIndex)} />
            {/* <Route
            exact path='/test'
            component={Test}
            /> */}
            <Route
              exact
              path="/search-google"
              component={this.wrapPage(SearchGoogle)}
            />
            <Route exact path="/search" component={this.wrapPage(Search)} />
            {/*<Route
            path="/author/:author_id"
            component={this.wrapPage(AuthorSection)}
            />*/}
            <Route
              exact
              path="/prave-teraz"
              component={this.wrapPage(RightNow)}
            />
            <Route
              exact
              path="/fotografia-dna/:gallery_url/:photo_slug"
              component={this.wrapPage(PhotoDayArticle)}
            />
            <Route
              exact
              path="/fotodennik/:gallery_url/:photo_slug"
              component={this.wrapPage(PhotodiaryArticle)}
            />
            <Route
              exact
              path="/foto-tasr/:gallery_url/:photo_slug"
              component={this.wrapPage(PhotoTASRArticle)}
            />
            <Route
              exact
              path="/fotografia-dna/:month"
              component={this.wrapPage(PhotoDayListing)}
            />
            <Route
              exact
              path="/fotografia-dna"
              component={() => {
                const now = new Date();
                return (
                  <Redirect to={`/fotografia-dna/${dateToMonthRepr(now)}`} />
                );
              }}
            />
            <Route
              exact
              path="/fotodennik"
              component={this.wrapPage(PhotodiaryListing)}
            />
            <Route
              exact
              path="/foto-tasr"
              component={this.wrapPage(PhotoTASRListing)}
            />
            <Route
              path="/:section_url/:article_url/:article_slug"
              component={this.wrapPage(Article)}
            />

            <Route
              path="/newsnow-redirect"
              component={this.wrapPage(NewsNowRedirect)}
            />
            <Route
              path="/volby-2023"
              component={this.wrapPage(PageVolby2023)}
            />
            <Route
              path="/volby-2024-druhe-kolo"
              component={this.wrapPage(PageVolby2024)}
            />
            <Route
              path="/volby-2024"
              component={this.wrapPage(PageVolby2024temp)}
            />
            <Route
              path="/eurovolby-2024-vysledky"
              component={this.wrapPage(PageEurovolby2024)}
            />
            <Route
              path="/odber-xml"
              component={this.wrapPage(PageSubscribeXML)}
            />
            {/*
            <Route
              path="/informacie-referendum-2023"
              component={this.wrapPage(Referendum)}
            />
            
            <Route
            exact path='/najcitanejsie'
            component={NoOp}
            />
            <Route
            exact path='/newsletter'
            component={NoOp}
            />
            <Route
            exact path='/kurzyvalut'
            component={NoOp}
            />
            <Route
            exact path='/historicky-kalendar'
            component={NoOp}
            />
            */}
            {/*           
            <Route
            path='/livescore'
            component={LivescorePage}
            />
            */}
            <Route path="/static" component={NoOp} />
            <Route component={this.wrapPage(CatchAll)} />
          </Switch>
        </ScrollToTop>
      </FacebookProvider>
    );
  }
}
